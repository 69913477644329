import React, { Component } from "react";

import dieselLogoIcon from '../../assets/white_diesel_logo.svg';
import homeApp from '../../assets/home_app.svg';
import responsiveHomeApp from '../../assets/responsive_home_app.png';
import appStore from '../../assets/app_store.svg';
import playStore from '../../assets/play_store.svg';

import './styles.css';

export class MobileApp extends Component {
  render() {
    return (
      <div id="app" className="mobileapp-container">
        <div className="row">
          <div className="mobileapp-content-container">
            <img
              src={dieselLogoIcon}
              alt="diesel-logo"
              className="responsive-logo-image"
            />
            <div>
              <span className="top-title-text">
                {this.props.data ? this.props.data.topTitle : "Carregando"}
              </span>
            </div>
            <div className="title-container">
              <img
                src={dieselLogoIcon}
                alt="diesel-logo"
                className="logo-image"
              />
              <h1 className="title-font">
                {this.props.data ? this.props.data.title : "Carregando"}
              </h1>
            </div>
            <div className="paragraph-container">
              <span className="paragraph-title">{this.props.data ? this.props.data.firstSubtitle : ""}</span>
              <p className="paragraph-content-text">
                {this.props.data ? this.props.data.firstParagraph : ""}
              </p>
            </div>
            <div className="paragraph-container">
              <span className="paragraph-title">{this.props.data ? this.props.data.secondSubtitle : ""}</span>
              <p className="paragraph-content-text">
                {this.props.data ? this.props.data.secondParagraph : ""}
              </p>
            </div>
            <div className="paragraph-container">
              <span className="paragraph-title">{this.props.data ? this.props.data.thirdSubtitle : ""}</span>
              <p className="paragraph-content-text">
                {this.props.data ? this.props.data.thirdParagraph : ""}
              </p>
            </div>
            <div className="download-container">
              <span className="download-text">Baixe agora:</span>
              <div style={{ display: 'flex' }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.test.dieselbank"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={playStore} alt="Play Store Button" />
                </a>
                <a
                  href="https://apps.apple.com/br/app/id1516013322"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '20px' }}
                >
                  <img src={appStore} alt="App Store Button" />
                </a>
              </div>
            </div>
          </div>
          <div className="image-container">
            <img
              src={homeApp}
              alt="App's home"
              className="home-app-image"
            />
            <img
              src={responsiveHomeApp}
              alt="App's home"
              className="responsive-home-app-image"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MobileApp;