import React, { Component } from "react";

import firstCashbackImage from '../../assets/fuel_cashback_1.png';
import secondCashbackImage from '../../assets/fuel_cashback_2.png';
import fuelIcon from '../../assets/fuel_icon.svg';
import cashbackIcon from '../../assets/cashback_icon.svg';

import './styles.css';

export class Fuel extends Component {
  render() {
    return (
      <div id="combustivel" className="container-background">
        <div className="container content">
          <div className="row">
            <div className="fuel-text-container">
              <img
                src={fuelIcon}
                alt="fuel-icon"
                className="responsive-fuel-icon"
              />
              <div>
                <span className="top-title fuel-top-title">
                  {this.props.data ? this.props.data.topTitle : "Carregando"}
                </span>
              </div>
              <div className="title-container">
                <img
                  src={fuelIcon}
                  alt="fuel-icon"
                  className="fuel-icon"
                />
                <h1 className="h1-font">
                  {this.props.data ? this.props.data.title : "Carregando"}
                </h1>
              </div>
              <p className="paragraph-container">
                <span className="paragraph-text">
                  {this.props.data ? this.props.data.paragraphBeforeHighlight : ""}
                </span>
                <span className="paragraph-text paragraph-highlighted-text">
                  {this.props.data ? this.props.data.paragraphHighlight : ""}
                </span>
                <span className="paragraph-text">
                  {this.props.data ? this.props.data.paragraphAfterHighlight : ""}
                </span>
              </p>
            </div>
            <div className="images-container">
              <img
                src={firstCashbackImage}
                alt="Cashback 1"
                className="first-cashback-image"
              />
              <img
                src={secondCashbackImage}
                alt="Cashback 2"
                className="second-cashback-image"
              />
            </div>
          </div>
          <div className="cashback-container">
            <img
              src={cashbackIcon}
              alt="cashback-icon"
              className="responsive-cashback-icon"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Fuel;