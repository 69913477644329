import React, { Component } from "react";

import whatsapp from '../../assets/whatsapp.svg';
import appStore from '../../assets/app_store.svg';
import playStore from '../../assets/play_store.svg';
import truck from '../../assets/truck_manifest.svg';
import dieselLogo from '../../assets/full_logo_white.svg';
import facebook from '../../assets/facebook.svg';
import twitter from '../../assets/twitter.svg';
import instagram from '../../assets/instagram.svg';
import youtube from '../../assets/youtube.svg';
import linkedin from '../../assets/linkedin.svg';

import './styles.css';

export class Footer extends Component {
  render() {
    return (
      <div id="footer" className="footer-container">
        <div className="first-content-container">
          <div className="column-container">
            <span className="subtitle-text">DieselBank</span>
            <div style={{ display: 'grid', marginTop: '24px' }}>
              <a href="#header" className="first-texts">
                Sobre nós
              </a>
              <a href="#fuel" className="first-texts">
                Abasteceu, ganhou
              </a>
              <a href="#transfer" className="first-texts">
                Envie e receba dinheiro
              </a>
              <a href="#boleto" className="first-texts">
                Pague contas e boletos
              </a>
              <a href="#card" className="first-texts">
                Cartão DieselBank
              </a>
              <a
                href="https://drive.google.com/drive/u/0/mobile/folders/1eVMJDKW5UexTxy-0x_D0-sgRcEIJG8ek?usp=sharing"
                className="first-texts"
                target="_blank"
                rel="noopener noreferrer"
              >
                Política de privacidade
              </a>
            </div>
          </div>
          <div className="column-container">
            <span className="subtitle-text">Para o Posto</span>
            <div style={{ display: 'grid' }}>
              <span className="first-texts first-texts-without-underline">
                Seja um parceiro
              </span>
              <span className="first-texts">
                parceiro@dieselbank.com.br
              </span>
            </div>
          </div>
          <div className="column-container">
            <span className="subtitle-text">Fale conosco</span>
            <div className="contact-container">
              <span className="first-texts paragraphs-without-underline">
                0800 887 1654
              </span>
              <span className="first-texts">
                <img
                  src={whatsapp}
                  alt="WhatsApp logo"
                  style={{ marginRight: '8px' }}
                />
                <a
                  href="https://wa.me/558008871654"
                  target="_blank"
                  rel="noopener noreferrer"
                >Whatsapp</a>
              </span>
              <span className="first-texts">
                atendimento@dieselbank.com.br
              </span>
            </div>
          </div>
          <div className="column-container open-account-container">
            <span className="subtitle-text">Abra sua conta</span>
            <a
              href="https://play.google.com/store/apps/details?id=com.test.dieselbank"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={playStore}
                alt="Play Store Button"
                style={{ marginTop: '20px' }}
              />
            </a>
            <a
              href="https://apps.apple.com/br/app/id1516013322"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={appStore}
                alt="App Store Button"
                style={{ marginTop: '20px' }}
              />
            </a>
          </div>
        </div>
        <hr className="solid" />
        <div className="second-container-content">
          <div className="truck-image">
            <img
              src={truck}
              alt="Truck manifest"
            />
          </div>
          <div className="first-paragraph-container">
            <p >
              {this.props.data ? this.props.data.firstParagraph : "Carregando"}
            </p>
          </div>
          <div className="second-paragraph-container">
            <p >
              {this.props.data ? this.props.data.secondParagraph : "Carregando"}
            </p>
          </div>
        </div>
        <hr className="solid" />
        <div className="third-container">
          <div className="diesel-logo-container">
            <img
              src={dieselLogo}
              alt="Diesel Logo"
            />
          </div>
          <div className="logos-container">
            <a href="#footer">
              <img
                src={facebook}
                alt="Facebook Logo"
                className="logos-img"
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/diesel-bank/?originalSubdomain=br"
              rel="noopener noreferrer"
            >
              <img
                src={linkedin}
                alt="Instagram Logo"
                className="logos-img"
              />
            </a>
            <a href="#footer">
              <img
                src={youtube}
                alt="Youtube Logo"
                className="logos-img"
              />
            </a>
            <a href="#footer">
              <img
                src={instagram}
                alt="Instagram Logo"
                className="logos-img"
              />
            </a>
            <a href="#footer">
              <img src={twitter} alt="Twitter Logo" />
            </a>
          </div>
        </div>
        <div className="info-container">
          <span>DieselBank Soluções - CNPJ: 36.231.001/0001-94</span>
          <span>R Jeronimo Pattaro, 160 | SALA 01-B | Barão Geraldo, Campinas - SP</span>
        </div>
      </div>
    );
  }
}

export default Footer;