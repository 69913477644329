import React, { Component } from 'react'
import Navigation from './pages/Navigation';
import Header from './pages/Header';
import Fuel from './pages/Fuel';
import Transfer from './pages/Transfer';
import Boleto from './pages/Boleto';
import Card from './pages/Card';
import Cost from './pages/Cost';
import MobileApp from './pages/MobileApp';
import Partners from './pages/Partners';
import BecomeAPartner from './pages/BecomeAPartner';
import Footer from './pages/Footer';
import Event from './pages/Event';
import FooterButton from './components/FooterButton';
import AppStoreRedirect from './pages/AppStoreRedirect';
import JsonData from './data/data.json';
import {BrowserRouter as Router, Route} from 'react-router-dom';

export class App extends Component {

  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({ landingPageData: JsonData });
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <Router>
        <Route path="/diad">
          <Event data={this.state.landingPageData}/>
        </Route>
        <Route exact path="/">
          <LandingPage data = {this.state.landingPageData}/>
        </Route>
        <Route exact path="/appStore">
          <AppStoreRedirect />
        </Route>
      </Router>
    )
  }
}

const LandingPage = ({data}) => {
    return(
      <div style={{ overflow: 'hidden' }}>
        <Navigation />
        <Header data={data.Header} />
        <Fuel data={data.Fuel} />
        <Transfer data={data.Transfer} />
        <Boleto data={data.Boleto} />
        <Card data={data.Card} />
        <Cost />
        <MobileApp data={data.MobileApp} />
        <BecomeAPartner data={data.BecomeAPartner} />
        <Partners data={data.Partners} />
        <Footer data={data.Footer} />
        <FooterButton />
      </div>
    )
}

export default App;
