import React, { Component } from "react";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import trucker from '../../assets/trucker.png';
import homeApp from '../../assets/home_app.svg';
import responsiveHomeApp from '../../assets/responsive_home_app.png';

import './styles.css';

export class Header extends Component {
  render() {
    return (
      <header id="sobre">
        <div className="header-container">
          <div className="content-text-container">
            <h1 className="h1-text">
              {this.props.data ? this.props.data.title : "Carregando"}
            </h1>
            <p className="highlighted-text">
              {this.props.data ? this.props.data.highlightedTitle : "Carregando"}
            </p>
            <a
              href="#app"
              className="btn btn-custom btn-lg page-scroll header-btn"
            >
              {this.props.data ? this.props.data.buttonText : "Carregando"}
              <ArrowForwardIcon style={{ marginLeft: '15px' }} fontSize="default" />
            </a>
          </div>
          <img
            src={trucker}
            alt="Trucker"
            className="trucker-image"
          />
        </div>
        <div className="second-content-container">
          <div className="grey-rectangle">
            <div className="header-image-container">
              <img
                src={homeApp}
                alt="App's home"
                className="home-app-image"
              />
              <img
                src={responsiveHomeApp}
                alt="App's home"
                className="responsive-home-app-image"
              />
            </div>
            <div className="second-part-texts-container">
              <h1 className="second-title-text">
                {this.props.data ? this.props.data.secondTitle : "Carregando"}
              </h1>
              <p className="second-part-texts">
                {this.props.data ? this.props.data.firstParagraph : "Carregando"}
              </p>
              <p className="second-part-texts">
                {this.props.data ? this.props.data.secondParagraph : "Carregando"}
              </p>
              <span className="bottom-text">
                {this.props.data ? this.props.data.bottomText : "Carregando"}
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
