import React from 'react';
import Navigation from '../Navigation';
import Hashtag from '../Hashtag';
import Date from '../Date';
import DieselBankTruckDriver from '../../assets/dieselbank-truckdriver.png';
import './styles.css';
import Partners from '../Partners';
import Footer from '../Footer';

const Event = ({data}) => {
  return(
    <>
      <Navigation />
      <CoverImg/>
      <Hashtag/>
      <Date/>
      <Partners data = {data.Partners}/>
      <Footer data= {data.Footer}/>
    </>
  )
}

const CoverImg = () => {
  return(
    <div className="cover-img">
      <img src={DieselBankTruckDriver} alt="dieselbank-truck-driver"/>
    </div>
  )
}

export default Event;
