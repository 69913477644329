import React from "react";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import PartnerModal from '../../components/PartnerModal';

import dieselLogoIcon from '../../assets/orange_diesel_logo.svg';

import './styles.css';

const BecomeAPartner = (props) => {
  // const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div className="partners-background-container">
      <div id="parceiros" className="rectangle">
        <div className="content-container">
          <img
            src={dieselLogoIcon}
            alt="diesel-logo"
            className="responsive-diesel-logo" />
          <div>
            <span className="top-title partners-top-title">
              {props.data ? props.data.topTitle : "Carregando"}
            </span>
          </div>
          <div className="title-container">
            <img
              src={dieselLogoIcon}
              alt="diesel-logo"
              className="diesel-logo"
            />
            <h1 className="h1-font">
              {props.data ? props.data.title : "Carregando"}
            </h1>
          </div>
          <p className="paragraph">
            {props.data ? props.data.firstParagraph : ""}
          </p>
          <p className="paragraph" style={{ marginTop: '30px' }}>
            {props.data ? props.data.secondParagraph : ""}
          </p>
          <a
            href="#footer"
            className="btn btn-partner btn-lg page-scroll"
          >
            {props.data ? props.data.buttonText : "Carregando"}
            <ArrowForwardIcon style={{ marginLeft: '15px' }} fontSize="large" />
          </a>
          {/* <PartnerModal open={open} handleClose={handleClose} /> 
          TODO: Add this modal after back-end implementation*/}
        </div>
      </div>
    </div>
  );
};

export default BecomeAPartner;
