import React from "react";

import './styles.css';

const Partners = ({data}) => {
  return (
    <div className="partners-container">
      <h3>Novos Postos Parceiros</h3>
      <div className="partners-row">
        {
          data ? data.partners.map(partner => {
            const {img, title, address, city} = partner
            return(
              <PartnerCard
                key = {title}
                img = {img}
                title = {title}
                address = {address}
                city = {city}
              />
            )
          }
          ) : <></>
        }
      </div>
    </div>
  );
};

const PartnerCard = ({img, title, address, city}) => {
  return (
    <div className="partners-card">
      <img src={require('../../assets/'+img+'.png')} alt={img}/>
      <h4 className="partners-card-text">{title}</h4>
      <p className="partners-card-text">{address}</p>
      <p className="partners-card-text">{city}</p>
    </div>
  )
}

export default Partners;
