import React, { useEffect } from "react";

const AppStoreRedirect = () => {
	useEffect(() => {
		if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
			window.location.href = 'https://play.google.com/store/apps/details?id=com.test.dieselbank';
			return;
		}
		if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
			window.location.href = 'https://apps.apple.com/br/app/dieselbank-para-caminhoneiros/id1516013322';
			return;
		}
		
		window.location.href = '/';
	});

	return (
		<div />
	);
};

export default AppStoreRedirect;
