import React, { Component } from "react";
import FooterButton from '../../components/FooterButton';

import logo from '../../assets/logo.svg';
import whatsapp from '../../assets/whatsapp.svg';
import {HashLink as Link} from 'react-router-hash-link'

import './styles.css';

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="navbar-header">
          <button
            id="button"
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll navbar-hidden-logo" href="#page-top">
            <img src={logo} alt="Logo" />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right navbar-default-content">
            <li>
              <Link to="/diad" className="page-scroll">
                Evento #diaD
                </Link>
            </li>
            <li>
              <Link to="/#combustivel" className="page-scroll">
                Ganhe dinheiro de volta
              </Link>
            </li>
            <li>
              <Link to="/#cartao" className="page-scroll">
                Cartão DieselBank
              </Link>
            </li>
            <li>
              <Link to="/#parceiros" className="page-scroll">
                Seja um posto parceiro
              </Link>
            </li>
            <div style={{ marginTop: '12px' }}>
              <a
                className="partners-button"
                target="_blank"
                href="https://posto.dieselbank.com.br/"
                rel="noopener noreferrer"
              >
                JÁ SOU UM PARCEIRO DIESELBANK
            </a>
            </div>
          </ul>

          <div className="navbar-hidden-content">
            <Link
              to="/#sobre"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              className="navbar-hidden-top-texts"
            >
              Sobre o DieselBank
            </Link>
            <Link
              href="/#combustivel"
              className="navbar-hidden-top-texts"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              Abasteceu, ganhou
            </Link>
            <Link
              to="/#cartao"
              className="navbar-hidden-top-texts"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              Cartão DieselBank
            </Link>
            <Link
              to="/#parceiros"
              className="navbar-hidden-top-texts"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              Seja um Posto Parceiro
            </Link>
          </div>

          <div className="navbar-contact-container navbar-hidden-content">
            <span className="navbar-contact-title">Fale conosco</span>
            <div className="navbar-contact-info-container">
              <span className="navbar-contact-texts">
                0800 887 1654
              </span>
              <span className="navbar-contact-texts navbar-contact-underlined">
                <img
                  src={whatsapp}
                  alt="WhatsApp logo"
                  style={{ marginRight: '8px' }}
                />
                <a
                  href="https://wa.me/558008871654"
                  target="_blank"
                  rel="noopener noreferrer"
                >Whatsapp</a>
              </span>
              <span className="navbar-contact-texts navbar-contact-underlined">
                contato@dieselbank.com.br
              </span>
            </div>
          </div>

          <FooterButton />
        </div>
      </nav>
    );
  }
}

export default Navigation;
