import React from 'react';
import dieselbankPhone from '../../assets/dieselbank-phone.png';
import './styles.css'

const Date = () => {
   return(
     <section className="date-section">
       <div className="date-left-side">
        <div className="date-text-container">
          <span>
            Inauguração
          </span>
          <h1>
            07 de Junho
          </h1>
          <p>
            A equipe DieselBank estará pronta para tirar as suas dúvidas. Você poderá abrir sua conta, retirar seu cartão na hora e já pode testar como funciona o dinheiro de volta do abastecimento. 
          </p>
          <p className="date-highlight">Além disso, teremos brindes incríveis!</p>
        </div>
       </div>
       <img src={dieselbankPhone} alt="dieselbank-phone"/>
     </section>
   )
 }

export default Date;
