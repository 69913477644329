import React from 'react';
import logo from '../../assets/grey_background_logo.svg'
import './styles.css';

const Hashtag = () => {
  return (
    <section className="hashtag-section">
      <img src={logo} className="grey-logo" alt="logo"/>
      <div className="hashtag-content">
        <p className="highlighted-text hashtag">
          #DiaD
        </p>
        <h1 className="h1-text">
          Evento de inauguração das novas parcerias 
        </h1>
        <p className="highlighted-text hashtag">
          DieselBank no Maranhão
        </p>
      </div>
    </section>
  )
}

export default Hashtag;
