import React, { Component } from "react";

import card from '../../assets/card.svg';
import cardIcon from '../../assets/card_icon.svg';

import './styles.css';

export class Card extends Component {
  render() {
    return (
      <div id="cartao">
        <div className="background-container">
          <div
            className="center-rectangle"
            style={{
              backgroundPosition: '67px',
            }}>
            <div className="card-content-container">
              <img
                src={card}
                alt="container"
                className="image-card-container"
              />
              <div className="text-container card-text-container">
                <img
                  src={cardIcon}
                  alt="icon"
                  className="responsive-card-icon"
                />
                <div>
                  <span className="top-title card-top-title">
                    {this.props.data ? this.props.data.topTitle : "Carregando"}
                  </span>
                </div>
                <div className="title-container">
                  <img
                    src={cardIcon}
                    alt="icon"
                    className="card-icon"
                  />
                  <h1 className="h1-font">
                    {this.props.data ? this.props.data.title : "Carregando"}
                  </h1>
                </div>
                <p className="paragraph-text-style">
                  {this.props.data ? this.props.data.firstParagraph : ""}
                </p>
                <p className="paragraph-text-style">
                  {this.props.data ? this.props.data.secondParagraph : ""}
                </p>
              </div>
              <img
                src={card}
                alt="container"
                className="responsive-image-card-container"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;