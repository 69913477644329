import React, { Component } from "react";

import './styles.css';

export class Cost extends Component {
  render() {
    return (
      <div id="custo" className="cost-container">
        <div className="cost-title-container">
          <span className="cost-title-text">
            Sabe quanto tudo isso custa pra você?
          </span>
        </div>
        <div className="cost-value-container">
          <span className="cost-currency-text">R$</span>
          <span className="cost-value-text">0,00</span>
        </div>
      </div>
    );
  }
}

export default Cost;
