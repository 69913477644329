import React from "react";

import './styles.css';

const FooterButton = () => {
  const operationalSystem = navigator.userAgent.toLowerCase();
  const isAndroid = operationalSystem.indexOf("android") > -1; // android check
  const isIphone = operationalSystem.indexOf("iphone") > -1; // ios check

  const handleClick = () => {
    if (isIphone === true) {
      const app = {
        launchApp: function () {
          setTimeout(function () {
            window.location.href = "https://apps.apple.com/br/app/id1516013322";
          }, 25);
          window.location.href = "bundlename://linkname"; //which page to open(now from mobile, check its authorization)
        },
        openWebApp: function () {
          window.location.href = "https://apps.apple.com/br/app/id1516013322";
        }
      };
      app.launchApp();
    } else if (isAndroid === true) {
      const app = {
        launchApp: function () {
          window.location.replace("bundlename://linkname"); //which page to open(now from mobile, check its authorization)
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function () {
          window.location.href = "https://play.google.com/store/apps/details?id=com.test.dieselbank";
        }
      };
      app.launchApp();
    }
  };

  return (
    <button
      className="button"
      onClick={handleClick}
    >
      <span className="button-text">Abra sua conta grátis</span>
    </button>
  );
};


export default FooterButton;
