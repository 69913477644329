import React, { Component } from "react";

import transfer from '../../assets/transfer.png';
import cashIn from '../../assets/cash_in.svg';

import './styles.css';

export class Transfer extends Component {
  render() {
    return (
      <div id="transfer" className="transfer-container">
        <div className="background-container">
          <div
            className="center-rectangle"
            style={{
              backgroundPosition: '67px'
            }}>
            <div className="transfer-content-container">
              <img
                src={transfer}
                alt="container"
                className="transfer-image"
              />
              <div className="transfer-text-container">
                <img
                  src={cashIn}
                  alt="icon"
                  className="responsive-cash-icon"
                />
                <div>
                  <span className="top-title transfer-top-title">
                    {this.props.data ? this.props.data.topTitle : "Carregando"}
                  </span>
                </div>
                <div className="title-container transfer-title-container">
                  <img
                    src={cashIn}
                    alt="icon"
                    className="cash-icon"
                  />
                  <h1 className="h1-font">
                    {this.props.data ? this.props.data.title : "Carregando"}
                  </h1>
                </div>
                <p className="paragraph-text-style">
                  {this.props.data ? this.props.data.paragraph : ""}
                </p>
              </div>
              <img
                src={transfer}
                alt="container"
                className="responsive-transfer-image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Transfer;